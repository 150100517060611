import { render, staticRenderFns } from "./d-upload.vue?vue&type=template&id=a9583870&scoped=true&"
import script from "./d-upload.vue?vue&type=script&lang=js&"
export * from "./d-upload.vue?vue&type=script&lang=js&"
import style0 from "./d-upload.vue?vue&type=style&index=0&id=a9583870&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9583870",
  null
  
)

export default component.exports