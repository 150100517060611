/* jshint esversion: 6 */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: '/register',
		name: 'register',
		component: () => import('@/views/register.vue')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login.vue')
	},
	{
		path: "/",
		name: "home",
		redirect: "/homeMain",
		component: () => import("../views/home.vue"),
		children: [
			{
				path: "/homeMain",
				name: "homeMain",
				component: () => import("../views/center/home-main"),
			},
			{
				path: "/process",
				name: "process",
				component: () => import("../views/center/process"),
			},
			{
				path: "/understanding",
				name: "understanding",
				component: () => import("../views/center/understanding"),
			},
			{
				path: "/onlineDeclaration",
				name: "onlineDeclaration",
				component: () => import("../views/center/onlineDeclaration"),
			},
			{
				path: "/personalCenter",
				name: "personalCenter",
				component: () => import("../views/center/personalCenter"),
			},
			{
				path: "/declareSelect",
				name: "declareSelect",
				component: () => import('../views/declare/declare-select.vue')
			},
			{
				path: "/grOnlineDeclaration",
				name: "grOnlineDeclaration",
				component: () => import("../views/declare/gr/onlineDeclaration"),
			},
			{
				path: "/gtgshOnlineDeclaration",
				name: "gtgshOnlineDeclaration",
				component: () => import("../views/declare/gtgsh/onlineDeclaration"),
			},
			{
				path: "/qyOnlineDeclaration",
				name: "qyOnlineDeclaration",
				component: () => import("../views/declare/qy/onlineDeclaration"),
			},
			{
				path: '/noticeList',
				name: 'noticeList',
				component: () => import('@/views/notice/list.vue')
			},
			{
				path: '/noticeDetails',
				name: 'noticeDetails',
				component: () => import('@/views/notice/details.vue')
			}
		],
	},
];

const router = new VueRouter({
	mode: "hash",
	// base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return {x: 0, y: 0};
		}
	},
});

export default router;

// router.beforeEach((to, from, next) => {
//   // 1.如果访问的是登录页面（无需权限），直接放行
//   if (
//     to.path === "/login" ||
//     to.path === "/home" ||
//     to.path === "/examCenter" ||
//     to.path === "/examInfo" ||
//     to.path === "/homeMain"
//   )
//     return next();
//   // 2.如果访问的是有登录权限的页面，先要获取token
//   next();
// });
